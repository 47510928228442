import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import arrow from '../assets/Icons/contactus-arrow.png';

export default function ContactUs() {
    return (
        <Container>
            <Row className="align-items-center text-center text-md-start">
                <Col xs={12} md={4} className="mb-3 mb-md-0">
                    <div style={{ fontSize: '30px', color: '#1A0950' }}>
                        Heard <br /> Enough?
                    </div>
                </Col>
                <Col xs={12} md={4} className="mb-3 mb-md-0">
                    <div style={{ 
                        fontSize: '60px', 
                        fontWeight: '600', 
                        color: '#1A0950', 
                        textAlign: 'center' 
                    }}>
                        Contact Us
                    </div>
                </Col>
                <Col xs={12} md={4} className="d-flex justify-content-center justify-content-md-end">
                    <Button
                        style={{
                            width: '80px',
                            height: '80px',
                            backgroundColor: '#0094DE',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        onClick={() => window.location.replace("/contact")}
                    >
                        <img style={{ width: '50px', height: '50px' }} src={arrow} alt="Arrow" />
                    </Button>
                </Col>
            </Row>
        </Container>
    );
}
