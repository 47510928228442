// First, create a shared configuration file for both service pages
export const servicePageStyles = {
  // Layout
  section: {
    marginTop: '3rem',
    padding: 'clamp(2rem, 5vw, 5rem) 0',
  },
  container: {
    padding: '0 clamp(1rem, 3vw, 3rem)',
  },
  
  // Typography
  heading: {
    fontSize: 'clamp(2rem, 5vw, 4rem)',
    fontWeight: '600',
    lineHeight: '1.2',
    marginBottom: '1.25rem',
  },
  subheading: {
    fontSize: 'clamp(1rem, 2vw, 1.25rem)',
    fontWeight: 'normal',
  },

  // Feature cards
  featureCard: {
    width: '70px',
    height: '70px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto 1rem',
  },
  featureText: {
    title: {
      fontSize: '15px',
      marginBottom: '0.5rem',
    },
    description: {
      fontSize: '13px',
      maxWidth: '200px',
      margin: '0 auto',
    },
  },

  // Buttons
  linkButton: {
    backgroundColor: 'transparent',
    color: '#F46036',
    border: 'none',
    fontSize: '1rem',
    fontWeight: '500',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    transition: 'color 0.3s ease',
  }
}; 