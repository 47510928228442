import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import brandImages from "../assets/BrandLogos/logos";

const BrandSlider = () => {
  const brandsArray = Object.values(brandImages);
  const containerRef = useRef(null);
  const [repeatCount, setRepeatCount] = useState(2); // minimum 2 for smooth loop

  useEffect(() => {
    const calculateRepeatCount = () => {
      if (!containerRef.current) return;

      const containerWidth = containerRef.current.offsetWidth;
      const estimatedLogoWidth = 140; // logo + padding
      const totalLogoWidth = brandsArray.length * estimatedLogoWidth;
      const minTotalWidth = containerWidth * 1.1; // slightly more than full width

      const neededRepeats = Math.ceil(minTotalWidth / totalLogoWidth);
      setRepeatCount(neededRepeats < 2 ? 2 : neededRepeats); // ensure at least 2
    };

    calculateRepeatCount();
    window.addEventListener("resize", calculateRepeatCount);
    return () => window.removeEventListener("resize", calculateRepeatCount);
  }, [brandsArray]);

  const repeatedBrands = Array(repeatCount).fill(brandsArray).flat();

  return (
    <div style={{ overflow: "hidden", padding: "20px 0" }}>
      <Container fluid ref={containerRef}>
        <div className="scroll-track">
          {repeatedBrands.map((logo, index) => (
            <div key={index} className="scroll-item">
              <img src={logo} alt={`Brand Logo ${index}`} />
            </div>
          ))}
        </div>
      </Container>

      <style>{`
        .scroll-track {
          display: flex;
          animation: scroll 15s linear infinite;
          width: max-content;
        }

        .scroll-item {
          flex: 0 0 auto;
          padding: 0 40px;
        }

        .scroll-item img {
          height: 60px;
          width: auto;
        }

        @keyframes scroll {
          0% { transform: translateX(0); }
          100% { transform: translateX(-50%); }
        }
      `}</style>
    </div>
  );
};

export default BrandSlider;
