import Setup1 from './Printing/SOLNA-Printing-Machine.jpg';
import Setup2 from './Printing/SORK-Printing-Machine.jpg';
import Setup3 from './Printing/SORM-S-Printing-Machine.jpg';
import Setup4 from './Printing/Heidelberg-Printing-Machines.jpg';
import Setup5 from './Printing/Al-Color-Prining-Machine.jpg';
import Setup6 from './Printing/SpotU-1.jpg';
import Setup7 from './Printing/UCoating.jpeg';
import Setup8 from './Printing/sorm.jpg';

const serviceImages = [
  { image: Setup1, caption: 'SOLNA Printing Machine' },
  { image: Setup2, caption: 'SORK Printing Machine' },
  { image: Setup3, caption: 'SORM-S Printing Machine' },
  { image: Setup4, caption: 'Heidelberg Printing Machine' },
  { image: Setup5, caption: 'Al-Color Printing Machine' },
  { image: Setup6, caption: 'Spot U.V.' },
  { image: Setup7, caption: 'UV Coating' },
  { image: Setup8, caption: 'SORM (HEIDELBERG) Double Color' },

];

export default serviceImages;
