import carousel1 from './carousel 1.png';
import carousel2 from './carousel 2.png';
import carousel3 from './carousel 3.png';
import landing2 from './Landing 2.png';
import landing4 from './Landing_4.jpg';
import bigbg from './BigBG.jpg'

const landingImages =  {
    carousel1, carousel2, carousel3, landing2, bigbg, landing4
}

export default landingImages;