import React from 'react';

const Subheading = ({ text, color }) => {
  return (
    <div style={{ textAlign: 'center', color: color || '#000',fontSize: '1.2rem' }}>
      {text}
    </div>
  );
};

const Heading = ({ text }) => {
  return (
    <div style={{ textAlign: 'center', fontWeight: '400', marginBottom: '2rem', fontSize: '2.25rem' }}>
      {text}
    </div>
  );
};

export { Subheading, Heading };
