import React from 'react';
import { Outlet } from 'react-router-dom';
import MyNavbar from './components/Navbar';
import Footer from './components/Footer';
import ChatBubble from './components/Chat';
import StaticLogo from './components/StaticLogo';

const Layout = () => {
  return (
    <>
      <MyNavbar />
      <main>
        <Outlet />
      </main>
      <ChatBubble />
      <Footer />
    </>
  );
};

export default Layout;