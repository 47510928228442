import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import landingImages from '../assets/Landing/landing';

const images = [
  landingImages.carousel1,
  landingImages.carousel2,
  landingImages.carousel3
];

const ProductsCarousel = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px', margin: '1rem 0' }}>
      <Carousel
        autoPlay={true}
        interval={2000}
        infiniteLoop={true}
        axis="horizontal"
        transitionTime={500}
        showThumbs={false}
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        centerMode={true}
        centerSlidePercentage={100}
      >
        {images.map((image, index) => (
          <div key={index} style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img
              src={image}
              alt={`Image ${index + 1}`}
              style={{
                maxWidth: '100%',
                maxHeight: '280px',
                objectFit: 'contain',
              }}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ProductsCarousel;
