import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Sample from '../../components/Sample';
import images from '../../assets/About/about';
import { Subheading, Heading } from '../../components/Headings';
import ContactUs from '../../components/ContactUs'
import BrandSlider from '../../components/BrandSlider';
import Section from '../../components/Section';
import samplemockup from '../../assets/Mockups Files/Hero Section/heroo.png';

export default function About() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Common styles that can be reused
  const commonStyles = {
    imageStyle: {
      width: '100%',
      height: 'auto',
      borderRadius: '8px',
      objectFit: 'cover',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    },
    sectionSpacing: {
      margin: '3rem 0',
      padding: '5rem 0',
    },
    textStyles: {
      paragraph: {
        fontSize: 'clamp(0.9rem, 1.5vw, 1.1rem)',
        lineHeight: '1.8',
        color: '#555454',
      }
    }
  };

  const spacingUtils = {
    marginY: {
      marginTop: 'clamp(2rem, 4vw, 3rem)',
      marginBottom: 'clamp(2rem, 4vw, 3rem)',
    },
    paddingY: {
      paddingTop: 'clamp(2rem, 4vw, 3rem)',
      paddingBottom: 'clamp(2rem, 4vw, 3rem)',
    }
  };

  return (
    <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column'}}>
      {/* First Section */}
      <Section style={{...spacingUtils.paddingY}}>
        <Container>
          <Row>
            <Col>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                <h1 style={{ 
                  fontSize: 'clamp(1rem, 2vw, 1.25rem)', 
                  fontWeight: 'normal' 
                }}>About Us</h1>
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: '#F46036',
                    marginLeft: '0.5rem',
                  }}
                ></div>
              </div>
              <h1 style={{ 
                fontSize: 'clamp(2.5rem, 5vw, 5.5rem)', 
                fontWeight: 'normal', 
                lineHeight: '1.2',
                marginBottom: '1rem'
              }}>
                Setting up Standard On <br />
                <span style={{ color: '#F46036' }}>Culture of working</span>
              </h1>
            </Col>
          </Row>
        </Container>

        <div style={{
          width: '100%',
          overflow: 'hidden',
          marginTop: '2rem',
          position: 'relative'
        }}>
          <img
            src={images.about1}
            alt="Team group"
            loading='lazy'
            style={{
              ...commonStyles.imageStyle,
              height: isMobile ? '300px' : '700px',
            }}
          />

          <div style={{
            backgroundColor: '#fff',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            padding: '2rem',
            borderRadius: '2px',
            zIndex: 10,
            ...(isMobile ? {
              position: 'relative',
              margin: '-3rem auto 0',
              maxWidth: '500px'
            } : {
              position: 'absolute',
              top: '30%',
              left: '60%',
              width: '30%',
              transform: 'translateY(-50%)'
            })
          }}>
            <h3 style={{ 
              fontSize: 'clamp(1.25rem, 2vw, 1.5rem)', 
              fontWeight: '700', 
              marginBottom: '1rem' 
            }}>
              Our story
            </h3>
            <p style={{ 
              fontSize: 'clamp(0.9rem, 1.5vw, 1rem)', 
              lineHeight: '1.6', 
              color: '#33' 
            }}>
              AIZA vision is to be a successful and a premier provider of all printing solutions by
              delivering the best product and services and to achieve an effective and prestigious
              presence in the national & international markets.
            </p>
            <Button 
              variant="link" 
              style={{ 
                fontSize: 'clamp(0.9rem, 1.5vw, 1rem)', 
                color: '#F46036', 
                padding: 0 
              }}
              onClick={() => window.scrollBy({ top: 600, behavior: 'smooth' })}
            >
              Learn More ↓
            </Button>
          </div>
        </div>
      </Section>

      {/* Second Section */}
      <Section backgroundColor="#f8f9ff" style={{...spacingUtils.paddingY}}>
        <Container>
          <Row className="align-items-center justify-content-center g-4">
            <Col md={6}>
              <Row>
                <Col md={7} className="mb-4 mb-md-0">
                  <img
                    src={images.about2}
                    alt="AIZA"
                    loading='lazy'
                    style={{
                      ...commonStyles.imageStyle,
                      height: isMobile ? 'auto' : '400px',
                    }}
                  />
                </Col>
                <Col md={5}>
                  <div style={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    gap: '2rem',
                    height: isMobile ? 'auto' : '400px',
                  }}>
                    <div style={{ flex: '1' }}>
                      <img
                        src={images.about3}
                        alt="AIZA"
                        loading='lazy'
                        style={{
                          ...commonStyles.imageStyle,
                          height: isMobile ? 'auto' : '180px',
                        }}
                      />
                    </div>
                    <div
                      style={{
                        flex: '1',
                        backgroundColor: '#6251AB',
                        color: '#fff',
                        padding: '1.5rem',
                        borderRadius: '8px',
                        fontSize: 'clamp(1rem, 1.5vw, 1.2rem)',
                        fontWeight: '500',
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: isMobile ? '120px' : '180px',
                      }}
                    >
                      We value our Customers Approach
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={1}/>
            <Col md={5}>
              <h2 style={{ 
                fontSize: 'clamp(1.8rem, 3vw, 2.5rem)', 
                fontWeight: '700', 
                marginBottom: '1.5rem' 
              }}>
                Who Are <span style={{ color: '#6A1B9A' }}>AIZA?</span>
              </h2>
              <p style={commonStyles.textStyles.paragraph}>
                AIZA Packages is a registered company established in 1995, located at SITE II Super
                Highway, Karachi. Having an efficient team of well-trained and professional members,
                we are pioneers in delivering quality services of offset printing and packages.
              </p>
              <p style={commonStyles.textStyles.paragraph}>
                AIZA believes in the philosophy of retaining good long-term customer relationships
                by ensuring on-time delivery of its quality services.
              </p>
            </Col>
          </Row>
        </Container>
      </Section>

      {/* Third Section */}
        <Section>
          <Row className="align-items-center">
          <Col md={6}>
            <h2
                style={{
                  fontSize: '4rem',
                  marginBottom: '1.25rem',
                  fontWeight: '600',
                  lineHeight: '1.2'
                }}>
                Our <span style={{color: '#0094DE'}}> Products </span> 
                </h2>
                <p>
                We offer a range of 20+ packaging products that are premium, yet affordable, for growing businesses. Since getting custom packaging can often be confusing and overwhelming, we've also streamlined the packaging workflow through our platform, making the process of getting custom packaging easy and transparent.
                </p>
                <Button style={{ backgroundColor: '#0094DE', borderColor: '#0094DE' }} onClick={() => window.location.replace("/products")}>
                Browse Products
              </Button>
            </Col>
            <Col md={6}>
            <img
                src={samplemockup}
                alt="Mockup 1"
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            </Col>
          </Row>
        </Section>

      {/* Fourth Section */}
      <Section backgroundColor="#f8f9ff" style={{...spacingUtils.paddingY}}>
        <Container>
          <Subheading text="Our Motto" color="#26145E" />
          <Heading text="Provide Quality and Gain Loyalty" />
          <p style={{
            ...commonStyles.textStyles.paragraph,
            textAlign: 'center', 
            fontSize: 'clamp(1.1rem, 2vw, 1.5rem)', 
            padding: '0 1rem'
          }}>
            AIZA's vision is to be a successful and a premier provider of all printing solutions by delivering the best product and services and to achieve an effective and prestigious presence in the national & international markets.
          </p>
          <BrandSlider/>
        </Container>
      </Section>

      {/* Fifth Section */}
      <Section style={{...spacingUtils.paddingY}}>
        <Sample/>
      </Section>

      {/* Sixth Section */}
      <Section backgroundColor="#f8f9ff" style={{...spacingUtils.paddingY}}>
        <Container>
          <Subheading text="Our Quality Policy" color="#F46036" />
          <Heading text="Committed to Delivering Quality You Can Trust" />
          
          <Row className="align-items-center justify-content-center g-5">
            <Col xs={12} md={5}>
              <img
                src={images.about4}
                alt="Mockup 1"
                loading='lazy'
                style={commonStyles.imageStyle}
              />
            </Col>
            
            <Col xs={12} md={5}>
              <p style={commonStyles.textStyles.paragraph}>
                "AIZA Packages" caters for the entire range of printing needs with persistence and precision, 
                applying the best quality practices and procedures of the printing industry, and offers 
                amazing results at an affordable price.
              </p>
              <p style={commonStyles.textStyles.paragraph}>
                The quality policy of "AIZA Packages" is to ensure "High Quality Printing" with precision 
                for every customer, achieving leadership in the market as a reputable and quality printer and supplier.
              </p>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section style={{...spacingUtils.paddingY}}>
        <ContactUs/>
      </Section>
    </div>
  );
}
