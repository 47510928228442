import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const ImageFrame = ({ imagePath, caption }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div
      style={{
        backgroundColor: '#ffffff',
        borderRadius: '8px',
        overflow: 'hidden',
        width: '100%',
        maxWidth: '320px',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.08)',
        transition: 'transform 0.2s ease, box-shadow 0.2s ease',
      }}
      className="image-frame"
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = 'translateY(-2px)';
        e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.06)';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = 'translateY(0)';
        e.currentTarget.style.boxShadow = '0 1px 3px rgba(0, 0, 0, 0.08)';
      }}
    >
      <div
        style={{
          position: 'relative',
          paddingTop: '75%', // 4:3 aspect ratio for more professional look
          backgroundColor: '#f6f8fa',
        }}
      >
        <img
          src={imagePath}
          alt={caption}
          loading="lazy"
          onLoad={() => setIsLoaded(true)}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            opacity: isLoaded ? 1 : 0,
            transition: 'opacity 0.3s ease',
          }}
        />
      </div>
      <div
        style={{
          padding: '16px',
          borderTop: '1px solid #f0f2f5',
          backgroundColor: '#ffffff',
        }}
      >
        <p
          style={{
            margin: 0,
            fontSize: '0.75rem',
            lineHeight: '1.6',
            color: '#1a2b3c',
            fontWeight: '500',
            letterSpacing: '0.01em',
          }}
        >
          {caption}
        </p>
      </div>
    </div>
  );
};

export default ImageFrame;
