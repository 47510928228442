import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import fast from "../../assets/Icons/fast.png";
import solution from "../../assets/Icons/solution.png";
import support from "../../assets/Icons/support.png";
import sustainable from "../../assets/Icons/sustainable.png";
import landingImages from '../../assets/Landing/landing';
import { Subheading, Heading } from '../../components/Headings';
import Sample from '../../components/Sample';
import ContactUs from '../../components/ContactUs';
import BrandSlider from '../../components/BrandSlider';
import Section from '../../components/Section';
import boxes from '../../assets/Mockups Files/mockup files/image1.png';
import leaf from '../../assets//Products/Leaflets/leaflet1.png';
import image2 from '../../assets/Products/MasterCarton/master1.jpg';
import image3 from '../../assets/Mockups Files/mockup files/boxes.png';
import newbox from '../../assets/Packaging - Featured Products/Stationary.png'


function Home() {
  const [currentText, setCurrentText] = useState('Product');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentText((prevText) => {
        if (prevText === 'Product') return 'Need';
        if (prevText === 'Need') return 'Vision';
        return 'Product';
      });
    }, 6000);

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      clearInterval(timer);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const colorStyles = {
    Product: { color: '#F46036' },
    Need: { color: '#26145E' },
    Vision: { color: '#0094DE' },
  };

  const isMobile = windowWidth <= 768;

  return (
    <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>

      {/* Section 1 */}
      <div style={{marginTop: '2rem', marginBottom: '3rem'}}>
      <Container>
      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '0.75rem', alignItems: 'center', textAlign: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h1 style={{ fontSize: '1rem', fontWeight: 'normal', marginBottom: '0px'}}>The Future of Packaging</h1>
        </div>
        <div style = {{marginBottom: '0.75rem'}}>
        <h2 style={{ 
  fontSize: 'clamp(2rem, 5vw, 4rem)',
  fontWeight: 'normal', 
  lineHeight: '1.2',
  textAlign: 'center'
}}>
  Innovative Solutions for <br/> Every{' '}
  <span style={{ 
    display: 'inline-block',
    position: 'relative',
    minWidth: '2ch',
    verticalAlign: 'baseline'
  }}>
    <span key={currentText} style={{ 
      display: 'inline-block',
      animation: 'fadeIn 0.8s ease',
      verticalAlign: 'baseline',
      ...colorStyles[currentText]
    }}>
      {currentText}
    </span>
  </span>
</h2>
        </div>
        <Button style={{ width: '200px', height: '50px', background: 'linear-gradient(270deg, #F46036, #26145E, #0094DE)',backgroundSize: '600% 600%',border: '#000', borderRadius: '50px', color: '#fff', animation: 'gradientMove 4s ease infinite', marginBottom: '0.75rem' }}  onClick={() => window.location.replace("/products")}>Pack Your Vision</Button>
      </div>
      <div style={{ backgroundImage: `url(${landingImages.bigbg})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', height: '50vh', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '2rem', borderRadius: '8rem', position: 'relative', overflow: 'hidden' }}>
      </div>
      <BrandSlider/>
      </Container>
      </div>

      {/* Second Section: Why Choose Us? */}
      <Section backgroundColor={'#F8F9FF'}>
          <Subheading text="Why Choose Us?" color="#F46036" />
          <Heading text="Exceptional Quality, Every Time" />
          <Row className="justify-content-center gap-5 mt-4">
            {[
              { img: solution, title: 'Custom Solutions', text: 'Tailored packaging to meet your unique needs and branding.' },
              { img: sustainable, title: 'Sustainable Practices', text: 'Eco-friendly materials and processes that protect the planet.' },
              { img: fast, title: 'Fast Turnaround', text: 'Quick production and delivery to meet tight deadlines.' },
              { img: support, title: 'Expert Support', text: 'Dedicated team ready to assist you at every step.' }
            ].map((item, index) => (
              <Col key={index} md={2} className="text-center">
                <div>
                  <div style={{ width: '100px', height: '100px', borderRadius: '50%', border: '1px solid #F46036', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 auto 1rem' }}>
                    <img src={item.img} alt={item.title} style={{ width: '40%' }} />
                  </div>
                  <h6 style={{ fontSize: '15px' }}>{item.title}</h6>
                  <p style={{ fontSize: '13px', maxWidth: '200px', margin: '0 auto' }}>{item.text}</p>
                </div>
              </Col>
            ))}
          </Row>
      </Section>

      {/* Third Section: Our Services */}
      <Section>
        <Subheading text="Our Services" color="#204AD7" />
        <Heading text="Packaging That Makes an Impact" />
        <div style={{ 
          padding: '2rem 0',
          position: 'relative',
          overflow: 'hidden'
        }}>
          <div style={{ 
            display: 'grid',
            gridTemplateColumns: isMobile ? '1fr' : 'repeat(3, 1fr)',
            gap: isMobile ? '1.5rem' : '2rem',
            maxWidth: '1400px',
            margin: '0 auto',
            padding: isMobile ? '0 1rem' : '0 2rem'
          }}>
            {/* Main Feature */}
            <div style={{ 
              gridRow: isMobile ? 'auto' : '1 / span 2',
              position: 'relative',
              borderRadius: '1rem',
              overflow: 'hidden',
              boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.12)',
              transition: 'transform 0.3s ease',
              cursor: 'pointer',
              height: isMobile ? '400px' : 'calc(600px + 2rem)',
              '&:hover': {
                transform: 'translateY(-8px)'
              }
            }}>
              <div style={{
                backgroundImage: `url(${boxes})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100%',
                position: 'relative',
                display: 'flex',
                alignItems: 'flex-end',
                padding: '2rem',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)',
                  zIndex: 1
                }
              }}>
                <div style={{ 
                  position: 'relative', 
                  zIndex: 2,
                  color: '#fff',
                  textAlign: 'left'
                }}>
                  <h3 style={{ 
                    fontSize: '1.5rem', 
                    fontWeight: '600',
                    marginBottom: '0.5rem'
                  }}>Custom Boxes</h3>
                  <p style={{ 
                    fontSize: '0.9rem',
                    opacity: 0.9
                  }}>Premium quality packaging solutions tailored to your needs</p>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div style={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: isMobile ? '1.5rem' : '2rem'
            }}>
              {[leaf, image3].map((image, index) => (
                <div key={index} style={{ 
                  height: isMobile ? '250px' : '300px',
                  backgroundImage: `url(${image})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  borderRadius: '1rem',
                  overflow: 'hidden',
                  boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
                  transition: 'transform 0.3s ease',
                  cursor: 'pointer',
                  position: 'relative',
                  '&:hover': {
                    transform: 'translateY(-4px)'
                  }
                }}>
                  <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'linear-gradient(to top, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 100%)',
                    display: 'flex',
                    alignItems: 'flex-end',
                    padding: '1.5rem'
                  }}>
                    <div style={{ color: '#fff' }}>
                      <h4 style={{ 
                        fontSize: '1.2rem', 
                        fontWeight: '600',
                        marginBottom: '0.25rem'
                      }}>Leaflets</h4>
                      <p style={{ 
                        fontSize: '0.85rem',
                        opacity: 0.9
                      }}>Professional printing services</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Left Column */}
            <div style={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: isMobile ? '1.5rem' : '2rem'
            }}>
              {[newbox, image2].map((image, index) => (
                <div key={index} style={{ 
                  height: isMobile ? '250px' : '300px',
                  backgroundImage: `url(${image})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  borderRadius: '1rem',
                  overflow: 'hidden',
                  boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
                  transition: 'transform 0.3s ease',
                  cursor: 'pointer',
                  position: 'relative',
                  '&:hover': {
                    transform: 'translateY(-4px)'
                  }
                }}>
                  <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'linear-gradient(to top, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 100%)',
                    display: 'flex',
                    alignItems: 'flex-end',
                    padding: '1.5rem'
                  }}>
                    <div style={{ color: '#fff' }}>
                      <h4 style={{ 
                        fontSize: '1.2rem', 
                        fontWeight: '600',
                        marginBottom: '0.25rem'
                      }}>Stationery</h4>
                      <p style={{ 
                        fontSize: '0.85rem',
                        opacity: 0.9
                      }}>Professional business materials</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Section>

      {/* Fourth Section: Our Vision */}
      <Section backgroundColor={'#F8F9FF'}>
          <Subheading text="Our Vision" color="#0094DE" />
          <Heading text="Shaping the Future of Packaging" />
          <Row className="align-items-center">
            <Col md={6} className="text-center">
              <img src={landingImages.landing2} alt="Mockup 2" style={{ maxWidth: '100%', height: 'auto' }} />
            </Col>
            <Col md={6} className="mt-4 mt-md-0">
              <p>
                AIZA Packages is a registered company established in 1995, Located at SITE II Super Highway, Karachi. Having an efficient team of well-trained and professional members, we pioneer in delivering quality services of offset printing and packaging.
                <br /><br />
                AIZA believes in the philosophy of retaining good long-term customer relationships by ensuring on-time delivery of its quality services.
                <br /><br />
                Our vision is to be a successful and premier provider of all printing solutions by delivering the best product and services, achieving an effective and prestigious presence in the national & international markets.
              </p>
            </Col>
          </Row>
      </Section>

      {/* Fifth Section: Try Us */}
      <Section>
        <Sample />
      </Section>

      <div style={{ padding: '2rem 0'}}>
        <ContactUs/>
        </div>

      <style type="text/css">
        {`
          @keyframes fadeIn {
            0% {
              opacity: 0;
              transform: translateY(8px);
            }
            100% {
              opacity: 1;
              transform: translateY(0);
            }
          }
          @keyframes gradientMove {
            0% {
              background-position: 0% 50%;
            }
            50% {
              background-position: 100% 50%;
            }
            100% {
              background-position: 0% 50%;
            }
          }
        `}
      </style>
    </div>
  );
}

export default Home;