import React, { useState } from "react";
import { Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import aizalogo from "../assets/Icons/Aiza Logo.png";
import { useNavigate } from "react-router-dom";

const ProductCard = ({ image, title, tagline, description }) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  return (
    <Card
      style={{
        width: "300px",
        minHeight: "275px", 
        borderRadius: "8px",
        overflow: "hidden",
        position: "relative", 
        padding: "12px",
        boxShadow: "0px 4px 10px rgba(20, 20, 20, 0.1)",
        border: "none",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >

      <div style={{ position: "absolute", top: "20px", left: "20px", zIndex: 10 }}>
        <img src={aizalogo} alt="Aiza Logo" style={{ width: "30px", height: "30px" }} />
      </div>

      <Card.Img
        variant="top"
        src={image}
        style={{
          width: "100%",
          height: "180px",
          objectFit: "cover",
          backgroundColor: "#f8f9ff",
          borderRadius: "8px",
        }}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgb(255, 248, 248)",
          opacity: isHovered ? 1 : 0,
          transition: "opacity 0.3s ease-in-out",
          padding: "15px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >

        <Card.Text style={{ fontSize: "0.75rem", color: "#000" }}>{description}</Card.Text>

        <Button style={{ backgroundColor: "#F46036", border: "none", fontSize: "0.85rem", marginTop: "10px" }} onClick={() => navigate("/contact")}>
          Request a Sample
        </Button>
        <Button style={{ backgroundColor: "black", border: "none", fontSize: "0.85rem", marginTop: "5px" }} onClick={() => navigate("/contact")}>
          Give a Review
        </Button>
      </div>

      <Card.Body>
        <Card.Title style={{ textAlign: "left", fontWeight: "bold", fontSize: "1rem" }}>{title}</Card.Title>
        <Card.Text style={{ textAlign: "left", fontSize: "0.75rem", color: "#585858", marginTop: "-5px" }}>
         {tagline}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default ProductCard;
