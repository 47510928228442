import React from "react";
import { Container } from "react-bootstrap";

const Section = ({ backgroundColor, background, children }) => {
  const sectionStyle = {
    background: background || backgroundColor || "transparent", 
    margin: "3rem 0",
    padding: (background || backgroundColor) ? "5rem 0" : "0",
  };

  return (
    <div style={sectionStyle}>
      <Container>
        {children}
      </Container>
    </div>
  );
};

export default Section;
