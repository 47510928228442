import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './layout';
import Home from './pages/Home/home';
import About from './pages/About/about';
import Contact from './pages/Contact/contact';
import Printing from './pages/Services/Printing';
import Packaging from './pages/Services/Packaging';
import Products from './pages/Products/products';


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} /> {/* Default route */}
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="products" element={<Products />} />
          <Route path="services/printing" element={<Printing />} />
          <Route path="services/packaging" element={<Packaging />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
