import logo1 from "../BrandLogos/logo1.png";
import logo2 from "../BrandLogos/logo2.png";
import logo4 from "../BrandLogos/logo4.png";
import logo5 from"../BrandLogos/logo5.png";
import logo6 from "../BrandLogos/logo6.png";
import logo7 from "../BrandLogos/logo7.png";
import logo9 from "../BrandLogos/logo9.png";
import logo10 from "../BrandLogos/logo10.png";
import logo11 from "../BrandLogos/logo11.png";

const brandImages = {
    logo1, logo2, logo4, logo5, logo6, logo7, logo9, logo10, logo11
}

export default brandImages;