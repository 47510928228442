import annualReport1 from './AnnualReports/annualReport1.jpg';
import annualReport2 from './AnnualReports/annualReport2.jpg';
import annualReport3 from './AnnualReports/annualReport3.jpg';

import brochure1 from './Brochures/brochure1.png';
import brochure2 from './Brochures/brochure2.png';
import brochure3 from './Brochures/brochure3.png';

import bunting1 from './Bunting/bunting1.png';
import bunting2 from './Bunting/bunting2.jpg';
import bunting3 from './Bunting/bunting3.jpg';

import calendar1 from './Calendars/calendar1.png';
import calendar2 from './Calendars/calendar2.png';
import calendar3 from './Calendars/calendar3.png';

import catalogue1 from './Catalogues/catalogue1.png';
import catalogue2 from './Catalogues/catalogue2.png';
import catalogue3 from './Catalogues/catalogue3.png';

import corrugatedBox1 from './CorrugatedBoxes/box1.png';
import corrugatedBox2 from './CorrugatedBoxes/box2.png';
import corrugatedBox3 from './CorrugatedBoxes/box3.png';

import docket1 from './Dockets/docket1.png';
import docket2 from './Dockets/docket2.png';
import docket3 from './Dockets/docket3.png';

import dropCard1 from './Dropcards/card1.png';
import dropCard2 from './Dropcards/card2.png';
import dropCard3 from './Dropcards/card3.png';

import folder1 from './Folders/folder1.png';
import folder2 from './Folders/folder2.png';
import folder3 from './Folders/folder3.png';

import flyer1 from './Flyers/flyer1.png';
import flyer2 from './Flyers/flyer2.png';
import flyer3 from './Flyers/flyer3.png';

import greetingCard1 from './GreetingCards/card1.png';
import greetingCard2 from './GreetingCards/card2.png';
import greetingCard3 from './GreetingCards/card3.png';

import label1 from './Labels/label1.png';
import label2 from './Labels/label2.png';
import label3 from './Labels/label3.png';

import leaflet1 from './Leaflets/leaflet1.png';
import leaflet2 from './Leaflets/leaflet2.png';
import leaflet3 from './Leaflets/leaflet3.png';

import newsletter1 from './Newsletters/letter1.jpg';
import newsletter2 from './Newsletters/letter2.png';
import newsletter3 from './Newsletters/letter3.png';

import officeStationary1 from './OfficeStationary/supplies1.png';
import officeStationary2 from './OfficeStationary/supplies2.png';
import officeStationary3 from './OfficeStationary/supplies3.png';

import poster1 from './Posters/poster1.png';
import poster2 from './Posters/poster2.png';
import poster3 from './Posters/poster3.png';

import sticker1 from './Stickers/sticker1.png';
import sticker2 from './Stickers/sticker2.png';
import sticker3 from './Stickers/sticker3.png';

import wobbler1 from './Wobblers/wobbler1.png';
import wobbler2 from './Wobblers/wobbler2.png';
import wobbler3 from './Wobblers/wobbler3.png';

import tag1 from './Tags/tag1.png';
import tag2 from './Tags/tag2.png';
import tag3 from './Tags/tag3.png';

import unitCarton1 from './UnitCartons/unit1.jpg';
import unitCarton2 from './UnitCartons/unit2.png';
import unitCarton3 from './UnitCartons/unit3.png';

import masterCarton1 from './MasterCarton/master1.jpg';
import masterCarton2 from './MasterCarton/master2.png';
import masterCarton3 from './MasterCarton/master3.jpg';

const productData = [
    {
      images: [unitCarton1, unitCarton2, unitCarton3],
      title: "Unit Cartons",
      description: "Perfect packaging to showcase your products in style with sturdy and elegant unit cartons designed for maximum shelf appeal.",
    },
    {
      images: [annualReport1, annualReport2, annualReport3],
      title: "Annual Reports",
      description: "Professionally crafted annual reports that combine precision and design to present your company’s achievements effectively.",
    },
    {
      images: [brochure1, brochure2, brochure3],
      title: "Brochures",
      description: "Versatile and informative brochures tailored to highlight your brand's key messages and services in a visually engaging format.",
    },
    {
      images: [bunting1, bunting2, bunting3],
      title: "Bunting",
      description: "Bright and eye-catching bunting designed to make your events stand out with custom branding and bold colors.",
    },
    {
      images: [calendar1, calendar2, calendar3],
      title: "Calendars",
      description: "Beautifully printed calendars that keep your brand in front of clients year-round, with custom designs and high-quality finish.",
    },
    {
      images: [catalogue1, catalogue2, catalogue3],
      title: "Catalogues",
      description: "Detailed and visually appealing catalogues that offer a comprehensive look at your products and services, making them easy to browse.",
    },
    {
      images: [corrugatedBox1, corrugatedBox2, corrugatedBox3],
      title: "Corrugated Boxes",
      description: "Durable and eco-friendly corrugated boxes, perfect for shipping and protecting your products with custom branding options.",
    },
    {
      images: [docket1, docket2, docket3],
      title: "Dockets",
      description: "Keep transactions organized with custom-designed dockets that provide clear, professional documentation of sales.",
    },
    {
      images: [dropCard1, dropCard2, dropCard3],
      title: "Drop Cards",
      description: "Compact and impactful drop cards designed for promotional campaigns, perfect for giveaways and direct marketing.",
    },
    {
      images: [folder1, folder2, folder3],
      title: "Folders",
      description: "Custom folders designed to keep your documents organized while leaving a lasting impression with stylish branding.",
    },
    {
      images: [flyer1, flyer2, flyer3],
      title: "Flyers",
      description: "High-quality, attention-grabbing flyers that effectively communicate your message and drive customer engagement.",
    },
    {
      images: [greetingCard1, greetingCard2, greetingCard3],
      title: "Greeting Cards",
      description: "Personalized greeting cards for special occasions that combine sentiment with a professional touch to engage your clients.",
    },
    {
      images: [label1, label2, label3],
      title: "Labels & Tags",
      description: "Custom labels designed to make your products stand out, offering versatility and durability for packaging and branding needs.",
    },
    {
      images: [leaflet1, leaflet2, leaflet3],
      title: "Leaflets",
      description: "Informative leaflets that help spread the word about your brand, products, or services in a compact and effective format.",
    },
    {
      images: [newsletter1, newsletter2, newsletter3],
      title: "Newsletters",
      description: "Engaging newsletters that keep your audience informed with company updates, product launches, and industry insights.",
    },
    {
      images: [officeStationary1, officeStationary2, officeStationary3],
      title: "Office Stationary",
      description: "Elegant and functional office stationery solutions that reflect your brand’s professionalism, from letterheads to envelopes.",
    },
    {
      images: [poster1, poster2, poster3],
      title: "Posters",
      description: "Bold and creative posters designed to captivate audiences, perfect for marketing, promotions, and brand exposure.",
    },
    {
      images: [sticker1, sticker2, sticker3],
      title: "Stickers",
      description: "Custom stickers that offer a fun and creative way to promote your brand, with high-quality printing and vibrant colors.",
    },
    {
      images: [wobbler1, wobbler2, wobbler3],
      title: "Wobblers",
      description: "Attention-grabbing wobblers designed for retail displays to highlight promotions and special offers, creating instant impact.",
    },
    {
      images: [masterCarton1, masterCarton2, masterCarton3],
      title: "Master Cartons",
      description: "Robust and reliable master cartons designed to securely transport and store bulk quantities of your products.",
    },
  ];
  
  export default productData;
  
