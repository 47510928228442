import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import ProductsCarousel from '../../components/ProductsSlider';
import ContactUs from '../../components/ContactUs';
import ProductCard from '../../components/ProductCard';
import check from '../../assets/Icons/check.png'
import Section from '../../components/Section';
import productData from '../../assets/Products/products';

export default function Products() {

    return (
      <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
  
        {/* First Section */}
        <Section>
          <Container>
            <Row className="align-items-center">
              <Col md={6}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                  <h1 style={{ fontSize: '1.25rem', fontWeight: 'normal' }}>Products</h1>
                  <div style={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: '#F46036', marginLeft: '0.5rem' }}></div>
                </div>
                <h2 style={{
                  fontSize: 'clamp(2rem, 5vw, 4rem)',
                  marginBottom: '1.25rem',
                  fontWeight: '500',
                  lineHeight: '1.2'
                }}>
                  Comprehensive Selection for <span style={{ color: '#5D1095' }}>Every Need</span>
                </h2>
  
                <div style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '1rem',
                  marginTop: '1.5rem'
                }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={check} alt="check" style={{ width: '20px', height: '20px', marginRight: '0.5rem' }} />
                    <span style={{ fontSize: '1rem', fontWeight: '500', color: '#383838' }}>Verified Suppliers</span>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={check} alt="check" style={{ width: '20px', height: '20px', marginRight: '0.5rem' }} />
                    <span style={{ fontSize: '1rem', fontWeight: '500', color: '#383838' }}>Made in Pakistan</span>
                  </div>
                </div>
              </Col>
  
              <Col md={6} className="text-center">
                <ProductsCarousel />
              </Col>
            </Row>
          </Container>
        </Section>
  
        {/* Second Section */}
        <Section backgroundColor={'#F8F9FF'}>
          <Container>
            <div className="text-center mb-5">
              <h1 style={{
                fontWeight: '600',
                color: '#383838',
                fontSize: 'clamp(2rem, 5vw, 3.5rem)',
                letterSpacing: '1px'
              }}>Our Products</h1>
            </div>
  
            <Row className="g-4">
              {productData.map((product, index) => (
                <Col 
                  key={index} 
                  xs={12} 
                  sm={6} 
                  md={4} 
                  lg={3} 
                  className="d-flex justify-content-center"
                >
                  <ProductCard {...product} />
                </Col>
              ))}
            </Row>
          </Container>
        </Section>
  
        <div style={{ padding: '2rem 0' }}>
          <ContactUs />
        </div>
      </div>
    );
  }