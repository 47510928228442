import React, { useState, useEffect} from 'react';
import emailjs from '@emailjs/browser';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Section from '../../components/Section';
import { contactImages } from '../../assets/Contact/contact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCommentDots} from '@fortawesome/free-regular-svg-icons';
import {faLocationDot, faPhone} from '@fortawesome/free-solid-svg-icons';
import tryusmockup from '../../assets/Sample Mockup.png';


const formStyles = {
  input: {
    padding: '0.75rem',
    borderRadius: '0.5rem',
    border: '1px solid #ddd',
    fontSize: 'clamp(0.875rem, 1.5vw, 1rem)',
  },
  button: {
    padding: '0.75rem 1.5rem',
    borderRadius: '0.5rem',
    fontSize: 'clamp(0.875rem, 1.5vw, 1rem)',
    transition: 'all 0.3s ease',
  }
};

export default function Contact() {
    const [contactFormData, setContactFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        contact_number: '',
        message: ''
    });

    const [sampleFormData, setSampleFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        contact_number: '',
        sample: '',
        message: ''
    });

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    // Automatically clear success/error message after 5 seconds
    useEffect(() => {
        if (success || error) {
            const timer = setTimeout(() => {
                setSuccess(false);
                setError(false);
            }, 5000);

            return () => clearTimeout(timer); // Cleanup on unmount
        }
    }, [success, error]);

    const handleContactChange = (e) => {
        setContactFormData({ ...contactFormData, [e.target.name]: e.target.value });
    };

    const handleSampleChange = (e) => {
        setSampleFormData({ ...sampleFormData, [e.target.name]: e.target.value });
    };

    const handleContactSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        emailjs.send(
            "service_i14x4up",
            "template_iar00gb",
            contactFormData,
            "7CH6xAaG6tRvxtdt7"
        ).then(
            () => {
                setSuccess(true);
                setError(false);
                setLoading(false);
                setContactFormData({
                    first_name: '',
                    last_name: '',
                    email: '',
                    contact_number: '',
                    message: ''
                });
            },
            () => {
                setError(true);
                setSuccess(false);
                setLoading(false);
            }
        );
    };

    const handleSampleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        emailjs.send(
            "service_i14x4up",
            "template_19rbswc",
            sampleFormData,
            "7CH6xAaG6tRvxtdt7"
        ).then(
            () => {
                setSuccess(true);
                setError(false);
                setLoading(false);
                setSampleFormData({
                    first_name: '',
                    last_name: '',
                    email: '',
                    contact_number: '',
                    sample: '',
                    message: ''
                });
            },
            () => {
                setError(true);
                setSuccess(false);
                setLoading(false);
            }
        );
    };
    return (
        <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>

            {/* First Section */}
            <div
                style={{
                    marginTop: '3rem',
                    flex: 1,
                    position: 'relative',
                    backgroundImage: `url(${contactImages.bggrid})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
            >
                <Container>
                    <Row className="align-items-center" style={{ minHeight: '50vh' }}>
                        <Col md={7} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: '3rem', paddingRight: '3rem' }}>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                                <h1 style={{ fontSize: '4.5rem', fontWeight: 'normal' }}>
                                    Contact <span style={{ color: '#0094DE' }}>AIZA</span>
                                </h1>
                                <div
                                style={{
                                    minWidth: '1.5rem',
                                    minHeight: '1.5rem',
                                    borderRadius: '50%',
                                    backgroundColor: '#F46036',
                                    marginLeft: '1rem',
                                    marginTop: '0.75rem',
                                }}
                                ></div>
                            </div>
                        </Col>
                        <Col md={5} style={{ display: 'flex', justifyContent: 'center', paddingLeft: '3rem', paddingRight: '3rem' }}>
                            <img
                                src={contactImages.contact1}
                                alt="Mockup 1"
                                style={{
                                    maxWidth: '100%',
                                    height: 'auto',
                                }}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Second Section */}
            <Section>
                <h5 style={{ textAlign: 'center', color: '#204AD7', marginBottom: '0.5rem' }}>Got an Idea?</h5>
                <h2 style={{ textAlign: 'center', fontWeight: '600', marginBottom: '3rem' }}>We've got the skills, Let's team up!</h2>
                <Row>
                    <Col md={4} style={{ display: 'flex', justifyContent: 'center', marginBottom: '2rem' }}>
                        <div style={{
                            border: '2px solid #204AD7',
                            borderRadius: '10px',
                            padding: '2rem',
                            width: '100%',
                            maxWidth: '300px',
                        }}>
                            <FontAwesomeIcon icon={faCommentDots} style={{color: "#496BDD", height: '2rem'}}/>                                              
                            <h5>Chat to Support</h5>
                            <p>We're here to help</p>
                            <Button style={{backgroundColor:'#496BDD'}}>Chat to Support</Button>
                        </div>
                    </Col>
                    <Col md={4} style={{ display: 'flex', justifyContent: 'center', marginBottom: '2rem' }}>
                        <div style={{
                            border: '2px solid #204AD7',
                            borderRadius: '10px',
                            padding: '2rem',
                            width: '100%',
                            maxWidth: '300px',
                        }}>
                            <FontAwesomeIcon icon={faLocationDot} style={{color: "#496BDD", height: '2rem'}} />                                              
                            <h5>Visit us</h5>
                            <p>Visit our Office HQ</p>
                            <Button style={{backgroundColor:'#496BDD'}}>Get Directions</Button>
                        </div>
                    </Col>
                    <Col md={4} style={{ display: 'flex', justifyContent: 'center', marginBottom: '2rem' }}>
                        <div style={{
                            border: '2px solid #204AD7',
                            borderRadius: '10px',
                            padding: '2rem',
                            width: '100%',
                            maxWidth: '300px',
                        }}>
                            <FontAwesomeIcon icon={faPhone} style={{color: "#496BDD", height: '2rem'}}/>
                            <h5>Call us</h5>
                            <p>Mon-Fri from 8am to 5pm</p>
                            <Button style={{backgroundColor:'#496BDD'}}>Call us</Button>
                        </div>
                    </Col>
                </Row>
            </Section>

            {/* Third Section */}
            <Section backgroundColor={'#F8F9FF'}>
                <h1 style={{ textAlign: 'center', color: '#0094DE', marginBottom: '2rem' }}>
                    We'd Love to Hear from You!
                </h1>
                <Row>
                    <Col md={6}>
                        <Form onSubmit={handleContactSubmit}>
                            <Row>
                                <Col xs={12} sm={6}>
                                    <Form.Group style={{ marginBottom: '1rem' }}>
                                        <Form.Control 
                                            style={{...formStyles.input}}
                                            type="text" 
                                            name="first_name" 
                                            placeholder="First Name" 
                                            onChange={handleContactChange}
                                            value={contactFormData.first_name}
                                            required 
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Form.Group style={{ marginBottom: '1rem' }}>
                                        <Form.Control 
                                            style={{...formStyles.input}}
                                            type="text" 
                                            name="last_name" 
                                            placeholder="Last Name" 
                                            onChange={handleContactChange}
                                            value={contactFormData.last_name}
                                            required 
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={6}>
                                    <Form.Group style={{ marginBottom: '1rem' }}>
                                        <Form.Control 
                                            style={{...formStyles.input}}
                                            type="email" 
                                            name="email" 
                                            placeholder="Email" 
                                            onChange={handleContactChange}
                                            value={contactFormData.email}
                                            required 
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Form.Group style={{ marginBottom: '1rem' }}>
                                        <Form.Control 
                                            style={{...formStyles.input}}
                                            type="text" 
                                            name="contact_number" 
                                            placeholder="Contact Number" 
                                            onChange={handleContactChange}
                                            value={contactFormData.contact_number}
                                            required 
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group style={{ marginBottom: '1rem' }}>
                                <Form.Control 
                                    style={{...formStyles.input}}
                                    as="textarea" 
                                    rows={5} 
                                    name="message" 
                                    placeholder="Type your message here" 
                                    onChange={handleContactChange}
                                    value={contactFormData.message}
                                    required 
                                />
                            </Form.Group>
                            <Button 
                                type="submit" 
                                style={{ ...formStyles.button, backgroundColor: '#0094DE', marginTop: '1rem', borderColor: '#0094DE' }} 
                                disabled={loading}
                            >
                                {loading ? "Sending..." : "Send Message"}
                            </Button>

                            {success && <p style={{ color: "green", marginTop: "1rem" }}>Message sent successfully!</p>}
                            {error && <p style={{ color: "red", marginTop: "1rem" }}>Failed to send message. Try again later.</p>}
                        </Form>
                    </Col>

                    <Col md={6} className="mt-4 mt-md-0">
                        <iframe
                            title="Aiza Packages Location"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462801.06807513325!2d66.53298537343748!3d25.01257189999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb347420b754d75%3A0x6d9c67a625869ff2!2sAiza%20Packages%20(Pvt.)%20Ltd!5e0!3m2!1sen!2s!4v1737823182438!5m2!1sen!2s"
                            width="100%"
                            height="300"
                            style={{ border: '0' }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </Col>
                </Row>
            </Section>

            {/* Fourth Section */}
            <Section>
                <Row>
                    <Col md={6}>
                    <h2
                        style={{
                            fontSize: '4rem',
                            marginBottom: '1.25rem',
                            fontWeight: '600',
                            lineHeight: '1.2'
                        }}>
                        Looking to try a <span style={{ color: '#E04730' }}>sample</span> first?
                        </h2>
                        <img src={tryusmockup} alt="Mockup 1" style={{maxWidth: '100%',height: 'auto' }}/>
                    </Col>
                    <Col md={1}>
                    </Col>
                    <Col md={5}>
                    <p>
                    Get your hands on a selection of our most popular boxes and packaging with a reimbursable sample kit.
                    </p>
                    <Form onSubmit={handleSampleSubmit}>
                    <Row>
                        <Col md={6}>
                            <Form.Group style={{ marginBottom: '1rem' }}>
                                <Form.Control 
                                    style={{...formStyles.input}}
                                    type="text" 
                                    placeholder="First Name" 
                                    name="first_name" 
                                    onChange={handleSampleChange}
                                    value={sampleFormData.first_name}
                                    required 
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group style={{ marginBottom: '1rem' }}>
                                <Form.Control 
                                    style={{...formStyles.input}}
                                    type="text" 
                                    placeholder="Last Name" 
                                    name="last_name" 
                                    onChange={handleSampleChange}
                                    value={sampleFormData.last_name}
                                    required 
                                />
                            </Form.Group>
                        </Col>
                    </Row>
        <Row>
            <Col md={6}>
                <Form.Group style={{ marginBottom: '1rem' }}>
                    <Form.Control 
                        style={{...formStyles.input}}
                        type="email" 
                        placeholder="Email" 
                        name="email" 
                        onChange={handleSampleChange}
                        value={sampleFormData.email}
                        required 
                    />
                </Form.Group>
            </Col>
            <Col md={6}>
                <Form.Group style={{ marginBottom: '1rem' }}>
                    <Form.Control 
                        style={{...formStyles.input}}
                        type="text" 
                        placeholder="Contact Number" 
                        name="contact_number" 
                        onChange={handleSampleChange}
                        value={sampleFormData.contact_number}
                        required 
                    />
                </Form.Group>
            </Col>
        </Row>
        <Form.Group style={{ marginBottom: '1rem' }}>
            <Form.Control 
                style={{...formStyles.input}}
                type="text" 
                placeholder="Sample Type" 
                onChange={handleSampleChange}
                value={sampleFormData.sample}
                name="sample" 
                required 
            />
        </Form.Group>
        <Form.Group style={{ marginBottom: '1rem' }}>
            <Form.Control 
                style={{...formStyles.input}}
                as="textarea" 
                rows={5} 
                placeholder="Type your message here" 
                onChange={handleSampleChange}
                value={sampleFormData.message}
                name="message" 
                required 
            />
        </Form.Group>
        <Button type="submit" style={{ ...formStyles.button, backgroundColor: '#E04730', marginTop: '1rem', borderColor: '#E04730'}} disabled={loading}>
                        {loading ? "Sending..." : "Send Message"}
                    </Button>
                    </Form>
                    {success && <p style={{ color: "green", marginTop: "1rem" }}>Message sent successfully!</p>}
                {error && <p style={{ color: "red", marginTop: "1rem" }}>Failed to send message. Try again later.</p>}
                    </Col>
                </Row>
            </Section>
        </div>
    );
}
