import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faMap } from '@fortawesome/free-regular-svg-icons';
import logo from '../assets/Icons/footerlogo.png';

const Footer = () => {
  return (
    <div>
      <footer style={{ backgroundColor: '#220C5E', color: '#FFFFFF', padding: '5rem 0 1.5rem 0' }}>
        <Container>
          <Row className="justify-content-between">
            {/* Left Section */}
            <Col md={9}>
              <Row style={{ borderBottom: '1px solid #fff', paddingBottom: '2rem' }}>
                <Col md={4} className="mb-4 text-md-start text-center">
                  <h2 style={{ fontSize: '2.5rem', lineHeight: '1.2' }}>All solutions under one roof</h2>
                </Col>
                <Col md={4} className="mb-4 text-md-start text-center">
                  <h5 style={{ fontSize: '1rem', fontWeight: 'bold', marginBottom: '1rem' }}>KARACHI</h5>
                  <p style={{ fontSize: '0.9rem', margin: '10px 0' }}>
                    <FontAwesomeIcon icon={faEnvelope} /> info@aizapackages.com
                  </p>
                  <p style={{ fontSize: '0.9rem', margin: '10px 0' }}>
                    <FontAwesomeIcon icon={faMap} /> Plot E-21, SITE Phase II, <br />
                    Super Highway, <br />
                    Karachi.
                  </p>
                  <a
                    href="https://maps.app.goo.gl/ckZ9Ty1g2pQaJ6xv5"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: '#FFFFFF',
                      textDecoration: 'none',
                      fontWeight: 'bold',
                      fontSize: '0.9rem',
                    }}
                  >
                    SEE ON MAP &rarr;
                  </a>
                </Col>
                <Col md={3} className="mb-4 text-md-start text-center">
                  <h5 style={{ fontSize: '1rem', fontWeight: 'bold', marginBottom: '1rem' }}>QUICK LINKS</h5>
                  <ul className="list-unstyled" style={{ fontSize: '0.9rem', lineHeight: '1.75' }}>
                    <li onClick={() => window.location.replace('/')} style={{ cursor: 'pointer' }}>Home</li>
                    <li onClick={() => window.location.replace('/about')} style={{ cursor: 'pointer' }}>About</li>
                    <li onClick={() => window.location.replace('/products')} style={{ cursor: 'pointer' }}>Products</li>
                    <li onClick={() => window.location.replace('/services/printing')} style={{ cursor: 'pointer' }}>Printing</li>
                    <li onClick={() => window.location.replace('/services/packaging')} style={{ cursor: 'pointer' }}>Packaging</li>
                  </ul>
                </Col>
              </Row>
            </Col>

            {/* Right Section (Logo & Social Icons) */}
            <Col md={3} className="d-flex flex-column align-items-center text-center mt-4">
              <img 
                src={logo} 
                alt="AIZA Packages Logo" 
                className="footer-logo"
              />
              <h5 style={{ fontSize: '1.5rem', margin: '20px 0 10px' }}>FOLLOW US</h5>
              <div className="d-flex justify-content-center">
                <a href="https://www.instagram.com/aiza.packages/" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faInstagram} className="social-icon" />
                </a>
                <a href="https://www.linkedin.com/company/aiza-packages-pvt-ltd/" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faLinkedin} className="social-icon" />
                </a>
                <a href="https://www.facebook.com/profile.php?id=61559044924234" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faFacebook} className="social-icon" />
                </a>
              </div>
            </Col>
          </Row>

          {/* Footer Bottom */}
          <Row className="mt-4">
            <Col>
              <p style={{ fontSize: '0.8rem', margin: 0, textAlign: 'center' }}>
                Copyright © {new Date().getFullYear()} AIZA Packages Pvt Ltd
              </p>
            </Col>
          </Row>
        </Container>
      </footer>

      {/* CSS for Responsive Design */}
      <style>
        {`
          .footer-logo {
            width: 50%; /* Default size for normal screens */
          }
          .social-icon {
            font-size: 1.5rem;
            margin: 0 10px;
            color: white;
          }
          .social-icon:hover {
            opacity: 0.8;
          }
          @media (max-width: 768px) {
            .footer-logo {
              width: 30%; /* Tablet size */
            }
            .social-icon {
              font-size: 1.2rem;
              margin: 0 5px;
            }
            footer h2 {
              font-size: 2rem;
            }
            footer h5 {
              font-size: 1rem;
            }
            .text-md-start {
              text-align: center;
            }
          }
          @media (max-width: 576px) {
            .footer-logo {
              width: 25%; /* Mobile size */
            }
            footer {
              padding: 3rem 0 1rem 0;
            }
            footer h2 {
              font-size: 1.75rem;
            }
            .text-md-start {
              text-align: center;
            }
          }
        `}
      </style>
    </div>
  );
};

export default Footer;
