import React from "react";
import "./Process.css";

const steps = [
  {
    title: "PRE-PRESS:",
    items: [
      "Composition",
      "Recording",
      "Formatting and Pagination of Text",
      "Reproduction of Pictures and Graphics",
      "Color Separation for Multicolor Printing & Plate Making",
    ],
  },
  {
    title: "PRESS:",
    items: [
      "Offset Printing",
      "Cutting",
      "Die-cutting",
      "Pasting",
      "Folding",
      "U.V Coating and Embossing",
      "Single and Multicolor Printing in various Formats",
      "Security Featured Printing",
    ],
  },
  {
    title: "POST-PRESS:",
    items: [
      "Hole Punching",
      "Lamination",
      "Wire/Spiral and Plastic Binding",
      "Shrink Wrapping",
      "Counting Machine",
      "Strapping Machine",
      "Book Binding",
      "Pin Machine",
      "Commercial Forms and several types of Folding",
    ],
  },
];

const Workflow = () => {
  return (
    <div className="workflow">
      <ul className="list-step-numbers">
        {steps.map((section, index) => (
                    <li
                    key={index}
                    className="workflow-section"
                    style={index < 2 ? { borderLeft: '2px solid #333' } : {}}
                  >
              <div className="section-title">{section.title}</div>
              <div className="workflow-steps">
                {section.items.map((item, idx) => (
                  <div key={idx} className="workflow-step">
                    {item}
                  </div>
                ))}
              </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Workflow;
