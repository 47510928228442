import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import samplemockup from "../assets/Sample Mockup.png";


export default function Sample() {
    return (
        <Container>
        <Row  className="align-items-center">
          <Col md={6}>
          <img
                  src={samplemockup}
                  alt="Mockup 1"
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
          </Col>
          <Col md={6}>
          <h2
              style={{
                fontSize: '4rem',
                marginBottom: '1.25rem',
                fontWeight: '600',
                lineHeight: '1.2'
              }}>
              Looking to try a <span style={{color: '#F46036'}}> sample </span> first?
              </h2>
              <p>
              Get your hands on a selection of our most popular boxes and packaging with a reimbursable sample kit.
              </p>
              <Button style={{ backgroundColor: '#F46036', borderColor: '#F46036' }} onClick={() => window.location.replace("/contact")}>
              Get a Sample
            </Button>
          </Col>
        </Row>
      </Container>
    )
}