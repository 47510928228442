import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import ImageFrame from '../../components/ImageFrame';
import eco from '../../assets/Icons/eco.png';
import instantquote from '../../assets/Icons/instantquote.png';
import lowmoq from '../../assets/Icons/lowmoq.png';
import best from '../../assets/Icons/best.png';
import image from '../../assets/Services(Packaging)/Packaging 1.png';
import Sample from '../../components/Sample'
import Workflow from '../../components/Process';
import ContactUs from '../../components/ContactUs';
import { Subheading, Heading } from '../../components/Headings';
import Section from '../../components/Section';
import serviceImages from '../../assets/Services(Packaging)/services';
import ProductCard from '../../components/MockupProducts';
import { useNavigate } from "react-router-dom";
import images from '../../assets/Packaging - Featured Products/images'

export default function Packaging() {

    const productData = [
        {
          image: [
            images.box,
          ],
          title: "Corrugated Boxes",
          tagline: "Durable, eco-friendly protection.",
          description: "Strong and eco-friendly corrugated boxes, perfect for safe and secure packaging solutions."
        },
        {
          image: [
            images.stationary,
          ],
          title: "Office Stationery",
          tagline: "Essentials for every office.",
          description: "A wide range of office stationery products, designed to keep your workspace organized and efficient."
        },
        {
          image: [
            images.tag,
          ],
          title: "Tags",
          tagline: "Label with precision.",
          description: "Custom tags for organizing products, making labeling clear and efficient."
        },
        {
            image: [
              images.carton,
            ],
            title: "Cartons",
            tagline: "Secure, stylish packaging.",
            description: "High-quality printed cartons designed to enhance your packaging, making it both fun and functional."
          }
      ];
      
 const navigate = useNavigate();
    return (
        <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            
            {/* First Section */}
            <div style={{marginTop: '3rem'}}>
            <Container>
                <Row className='align-items-center'>
                <Col md={7}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                    <h1 style={{ fontSize: '1.25rem', fontWeight: 'normal' }}>Our Services</h1>
                    <div
                        style={{
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                        backgroundColor: '#F46036',
                        marginLeft: '0.5rem',
                        }}
                    ></div>
                    </div>
                    <h2
                    style={{
                        fontSize: '4rem',
                        marginBottom: '1.25rem',
                        fontWeight: '600',
                        lineHeight: '1.2',
                        position: 'relative',
                    }}
                    >
                    Secure, Sustainable, and Superior <span style={{color: '#220C5E'}}>Packaging Solutions</span>
                    </h2>
                </Col>
                <Col md={5} className="text-center">
                <img src={image} alt="Mockup 1" style={{ maxWidth: '100%', height: 'auto' }} />
                </Col>
                </Row>
            </Container>
            </div>

            {/* Second Section: Why Choose Us? */}
            <Section>
                <h3 style={{ textAlign: 'center', marginBottom: '0.5rem' }}>
                    Why <span style={{ color: '#0094DE' }}>Choose</span> Us?
                </h3>
                <Row className='justify-content-center gap-5 mt-4'>
                {[
                    { image: eco, title: "Eco-friendly materials", description: "Sustainable packaging to promote reusability." },
                    { image: instantquote, title: "Instant Quotation", description: "Consult and get an estimate immediately" },
                    { image: lowmoq, title: "Low MOQs", description: "Custom made boxes to cater to your needs" },
                    { image: best, title: "Best Quality", description: "Ensuring quality produce for our clients" },
                ].map((item, index) => (
                    <Col md={2} key={index} className="text-center">
                    <div>
                        <div style={{ width: '70px', height: '70px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 auto 1rem' }}>
                        <img src={item.image} alt={item.title} style={{ width: '60%' }} />
                        </div>
                        <h6 style={{ fontSize: '15px' }}>{item.title}</h6>
                        <p style={{ fontSize: '13px', maxWidth: '200px', margin: '0 auto' }}>{item.description}</p>
                    </div>
                    </Col>
                ))}
                </Row>
            </Section>
            
             {/* Third Section: Workflow */}
            <Section>
                <Subheading text="Our Services" color="#204AD7" />
                <Heading text="The Process that Ensures Trust" />
                <Workflow/>
            </Section>

            {/* Fourth Section: Our setup */}
            <Section backgroundColor="#f8f9ff">
                <Container>
                    <div style={{ textAlign: 'center', marginBottom: '3rem' }}>
                        <Subheading text="Our Setup" color="#0094DE" />
                        <Heading text="Providing quality through innovation" />
                        <p style={{ 
                            fontSize: 'clamp(0.9rem, 2vw, 1.1rem)',
                            color: '#666',
                            maxWidth: '700px',
                            margin: '1.5rem auto 0',
                            lineHeight: '1.6',
                            padding: '0 1rem'
                        }}>
                            Discover our state-of-the-art facilities and innovative processes that ensure the highest quality packaging solutions
                        </p>
                    </div>
                    <Row className="justify-content-center g-4">
                        {serviceImages.map((item, index) => (
                            <Col key={index} xs={12} sm={6} md={3} className="d-flex justify-content-center">
                                <div style={{ maxWidth: '300px', width: '100%' }}>
                                    <ImageFrame 
                                        imagePath={item.image} 
                                        caption={item.caption} 
                                    />
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </Section>

            {/* Fifth Section: Try Us */}
            <div style={{ padding: '5rem 0' }}>
                <Container>
                <Sample />
                </Container>
            </div>

            {/* Sixth Section: Mockup Products */}
            <div style={{ backgroundColor: '#f8f9ff',padding: '5rem 0' }}>
            <Container>
            <h2 style={{ textAlign: 'center', fontWeight: '600', marginBottom: '3rem', color: '#F46036'}}>Mockup Products</h2>
            <Row className="justify-content-center g-4" style={{ display: "flex", flexWrap: "wrap" }}>
                {productData.map((item, index) => (
                  <Col key={index} xs={12}  sm={6}  md={4}  lg={3}  xl={3} className="d-flex justify-content-center">
                    <ProductCard 
                    image={item.image} 
                    title = {item.title}
                    tagline={item.tagline} 
                    description={item.description}
                />
                  </Col>
                ))}
              </Row>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '2rem' }}>
                <button 
                    onClick={() => navigate("/products")} 
                    style={{
                    backgroundColor: 'transparent',
                    color: '#F46036',
                    border: 'none',
                    fontSize: '1rem',
                    fontWeight: '500',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    transition: 'color 0.3s ease',
                    }}
                    onMouseOver={(e) => e.target.style.color = '#d6492d'}
                    onMouseOut={(e) => e.target.style.color = '#F46036'}
                >
                    See More 
                    <span style={{ marginLeft: '0.5rem', transform: 'rotate(-45deg)',  }}>→</span>
                </button>
                </div>
            </Container>
            </div>

            <div style={{ padding: '2rem 0'}}>
            <ContactUs/>
            </div>
        </div>

    )
}