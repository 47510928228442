import React from "react";
import whatsappIcon from "../assets/Icons/whatsapp-icon.png";

const ChatBubble = () => {
  return (
    <div style={{
      position: "fixed",
      bottom: "5px",
      right: "5px",
      zIndex: 1000,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      gap: "12px",
      padding: "15px",
    }}>
      {/* Chat Bubble */}
      <div style={{
        backgroundColor: "#128c7e",
        padding: "14px 20px",
        borderRadius: "20px",
        maxWidth: "260px",
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
        position: "relative",
        fontSize: "14px",
        color: "#fff",
        fontWeight: "500",
        textAlign: "right"
      }}>
        Hey there! How can we assist you today?
        <div style={{
          position: "absolute",
          bottom: "-10px",
          right: "20px",
          width: "0",
          height: "0",
          borderLeft: "10px solid transparent",
          borderRight: "10px solid transparent",
          borderTop: "10px solid #128c7e"
        }}></div>
      </div>

      {/* WhatsApp Chat Bubble */}
      <div
        style={{
          backgroundColor: "#128c7e",
          borderRadius: "50%",
          boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
          padding: "8px",
          cursor: "pointer",
        }}
      >
        <a
          href="https://api.whatsapp.com/send?phone=923002241515"
          target="_blank"
          rel="noopener noreferrer"
          style={{ display: "block" }}
        >
          <img
            src={whatsappIcon}
            alt="WhatsApp Icon"
            style={{
              width: "45px",
              height: "45px",
              borderRadius: "50%",
            }}
          />
        </a>
      </div>
    </div>
  );
};

export default ChatBubble;