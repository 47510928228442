import React, { useState, useEffect } from "react";
import { Card, Modal,Carousel  } from "react-bootstrap";
import aizalogo from "../assets/Icons/Aiza Logo.png";

const ProductCard = ({ images, title, description }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [selectedImage, setSelectedImage] = useState(images[0]);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const preloadImages = async () => {
      try {
        await Promise.all(
          images.map((img) => {
            return new Promise((resolve, reject) => {
              const image = new Image();
              image.src = img;
              image.onload = resolve;
              image.onerror = reject;
            });
          })
        );
        setImagesLoaded(true);
      } catch (error) {
        console.error("Error preloading images:", error);
      }
    };

    preloadImages();
  }, [images]);

  const toggleExpand = () => {
    setIsClicked((prev) => !prev);
  };

  const handleImageClick = (e) => {
    e.stopPropagation();
    setShowModal(true);
  };

  return (
    <>
      <Card
        style={{
          width: "300px",
          height: isClicked ? "320px" : isHovered ? "320px" : "300px",
          borderRadius: "12px",
          overflow: "hidden",
          transition: "all 0.4s cubic-bezier(0.4, 0, 0.2, 1)",
          position: "relative",
          padding: "16px",
          boxShadow: isHovered
            ? "0px 8px 20px rgba(0, 0, 0, 0.15)"
            : "0px 4px 12px rgba(0, 0, 0, 0.08)",
          border: "none",
          backgroundColor: "#ffffff",
          cursor: "pointer",
          transform: isHovered ? "translateY(-4px)" : "translateY(0)",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={toggleExpand}
      >
        <div
          style={{
            position: "absolute",
            top: "24px",
            left: "24px",
            zIndex: 10,
          }}
        >
          <img
            src={aizalogo}
            alt="Aiza Logo"
            style={{ width: "30px", height: "30px" }}
          />
        </div>

        <div
          style={{
            width: "100%",
            height: "200px",
            position: "relative",
            borderRadius: "8px",
            overflow: "hidden",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.06)",
            backgroundColor: "#f8f9ff",
          }}
        >
          <Card.Img
            variant="top"
            src={selectedImage}
            onClick={handleImageClick}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              backgroundColor: "#f8f9ff",
              transition: "opacity 0.3s ease",
              opacity: imagesLoaded ? 1 : 0.5,
              cursor: "zoom-in",
            }}
          />
        </div>

        {(isHovered || isClicked) && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "12px",
              gap: "8px",
              padding: "4px",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              borderRadius: "8px",
              backdropFilter: "blur(4px)",
            }}
          >
            {images.map((img, index) => (
              <img
                key={index}
                src={img}
                alt={`Thumbnail ${index + 1}`}
                style={{
                  width: "44px",
                  height: "44px",
                  cursor: "pointer",
                  border:
                    img === selectedImage
                      ? "2px solid #000000"
                      : "2px solid transparent",
                  borderRadius: "6px",
                  transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
                  objectFit: "contain",
                  backgroundColor: "#f8f9ff",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  transform: img === selectedImage ? "scale(1.05)" : "scale(1)",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedImage(img);
                }}
              />
            ))}
          </div>
        )}

        <Card.Body style={{ padding: "16px 0 0 0" }}>
          <Card.Title
            style={{
              textAlign: "left",
              fontWeight: "600",
              fontSize: "1.1rem",
              marginBottom: "8px",
              color: "#1a1a1a",
              lineHeight: "1.4",
            }}
          >
            {title}
          </Card.Title>
          <Card.Text
            style={{
              textAlign: "left",
              fontSize: "0.85rem",
              color: "#666666",
              lineHeight: "1.5",
              margin: 0,
            }}
          >
            {description}
          </Card.Text>
        </Card.Body>
      </Card>

<Modal
  show={showModal}
  onHide={() => setShowModal(false)}
  centered
  size="lg"
  backdrop={true}
  keyboard={true}
  contentClassName="border-0 rounded-4 overflow-hidden"
>
  {/* Close Icon (Top Right) */}
  <div
    onClick={() => setShowModal(false)}
    style={{
      position: "absolute",
      top: "12px",
      right: "16px",
      zIndex: 1051,
      cursor: "pointer",
      fontSize: "2rem",
      color: "#000",
      fontWeight: "500",
      lineHeight: "1",
    }}
    aria-label="Close"
  >
    &times;
  </div>

  <Modal.Body
    style={{
      padding: 0,
      backgroundColor: "#ffffff",
      borderRadius: "1rem",
    }}
  >
    <Carousel
      interval={null}
      indicators={true}
      fade={false}
      defaultActiveIndex={images.findIndex((img) => img === selectedImage)}
      touch={true}
      nextIcon={
        <span
          aria-hidden="true"
          className="carousel-control-next-icon"
          style={{ filter: "invert(1)" }}
        />
      }
      prevIcon={
        <span
          aria-hidden="true"
          className="carousel-control-prev-icon"
          style={{ filter: "invert(1)" }}
        />
      }
    >
      {images.map((img, index) => (
        <Carousel.Item key={index}>
          <div
            style={{
              height: "65vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#f5f5f5",
              padding: "20px",
            }}
          >
            <img
              src={img}
              alt={`Slide ${index + 1}`}
              style={{
                maxHeight: "100%",
                maxWidth: "100%",
                objectFit: "contain",
                borderRadius: "12px",
                boxShadow: "0 6px 20px rgba(0, 0, 0, 0.1)",
              }}
            />
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  </Modal.Body>

  {/* Custom Indicator Styles */}
  <style>
    {`
      .carousel-indicators li {
        background-color: #333;
      }

      .carousel-indicators .active {
        background-color: #000; 
      }
    `}
  </style>
</Modal>
    </>
  );
};

export default ProductCard;
