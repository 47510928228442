import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Container, Image, Dropdown } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import img from '../assets/Icons/logo.png';

const MyNavbar = () => {
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);

  const pageTitles = {
    '/': 'Home - AIZA Packages (Pvt) Ltd.',
    '/about': 'About Us - AIZA Packages (Pvt) Ltd.',
    '/products': 'Our Products - AIZA Packages (Pvt) Ltd.',
    '/services/printing': 'Printing Services - AIZA Packages (Pvt) Ltd.',
    '/services/packaging': 'Packaging Services - AIZA Packages (Pvt) Ltd.',
    '/contact': 'Contact Us - AIZA Packages (Pvt) Ltd.',
  };

  useEffect(() => {
    document.title = pageTitles[location.pathname] || 'AIZA Packages (Pvt) Ltd.';
    window.scrollTo(0, 0); // Add this line to scroll to top
  }, [location.pathname]);

  const pages = [
    { name: 'Home', path: '/' },
    { name: 'About', path: '/about' },
    { name: 'Products', path: '/products' },
  ];

  const servicesDropdown = [
    { name: 'Printing', path: '/services/printing' },
    { name: 'Packaging', path: '/services/packaging' },
  ];

  const isServicesActive = servicesDropdown.some((service) => location.pathname.startsWith(service.path));

  const navLinkStyle = {
    fontSize: '16px',
    color: '#000',
    textDecoration: 'none',
    marginRight: '20px',
    padding: '10px 15px',
    transition: 'all 0.3s ease',
    position: 'relative',
  };

  const activeLinkStyle = {
    color: '#000',
    fontWeight: '500',
  };

  const dropdownToggleStyle = {
    ...navLinkStyle,
    border: 'none',
    backgroundColor: 'transparent',
  };

  const dropdownMenuStyle = {
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  };

  const dropdownItemStyle = {
    fontSize: '14px',
    padding: '8px 15px',
    transition: 'all 0.3s ease',
  };

  const activeDotStyle = {
    content: '""',
    position: 'absolute',
    width: '6px',
    height: '6px',
    backgroundColor: '#F46036',
    borderRadius: '50%',
    top: '0px',
    left: '50%',
    transform: 'translateX(-50%)',
  };
  

  return (
    <Navbar expand="lg" expanded={expanded} className="custom-navbar" sticky='top'>
      <Container>
      <Navbar.Brand
            as={NavLink}
            to="/"
            style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
          >
        <Image src={img} width="60" height="60" alt="AIZA Packages Logo" />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <span style={{ fontSize: '20px', color: '#000', fontWeight: '500' }}>
            AIZA Packages (Pvt) Ltd.
          </span>
          <span style={{ fontSize: '14px', color: '#777', fontWeight: '500' }}>All Solutions Under One Roof</span>
        </div>
      </Navbar.Brand>


        {/* Mobile Toggle Button */}
        <Navbar.Toggle
          aria-controls="navbar-nav"
          onClick={() => setExpanded(expanded ? false : "expanded")}
          className="custom-toggle"
        >
          <FontAwesomeIcon icon={faBars} size="lg" color="#000" />
        </Navbar.Toggle>

        <Navbar.Collapse id="navbar-nav">
          <Nav className="ms-auto">
            {pages.map(({ name, path }) => (
              <Nav.Link
                key={path}
                as={NavLink}
                to={path}
                end={path === '/'}
                style={({ isActive }) => ({
                  ...navLinkStyle,
                  ...(isActive ? activeLinkStyle : {}),
                  position: 'relative'
                })}
                onClick={() => setExpanded(false)}
              >
                 {({ isActive }) => (
                  <>
                    {name}
                    {isActive && <span style={activeDotStyle}></span>}
                  </>
                )}
              </Nav.Link>
            ))}

            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle
                as={Nav.Link}
                style={{
                  ...dropdownToggleStyle,
                  ...(isServicesActive ? activeLinkStyle : {}),
                }}
              >
                Services
                {isServicesActive && <span style={activeDotStyle}></span>}
              </Dropdown.Toggle>
              <Dropdown.Menu style={dropdownMenuStyle}>
                {servicesDropdown.map(({ name, path }) => (
                  <Dropdown.Item
                    key={path}
                    as={NavLink}
                    to={path}
                    style={({ isActive }) => ({
                      ...dropdownItemStyle,
                      ...(isActive ? { fontWeight: 'bold', color: '#000' } : {}),
                    })}
                    onClick={() => setExpanded(false)}
                  >
                     {({ isActive }) => (
                  <>
                    {name}
                    {isActive && <span style={activeDotStyle}></span>}
                  </>
                )}
                  </Dropdown.Item>                
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <Nav.Link
              key="/contact"
              as={NavLink}
              to="/contact"
              style={({ isActive }) => ({
                ...navLinkStyle,
                ...(isActive ? activeLinkStyle : {}),
                position: 'relative'
              })}
              onClick={() => setExpanded(false)}
            >
               {({ isActive }) => (
                  <>
                    Contact
                    {isActive && <span style={activeDotStyle}></span>}
                  </>
                )}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>

      {/* Custom Styles */}
      <style>
        {`
          .custom-navbar {
            background-color: #fff;
            padding: 10px 0;
          }

          .custom-toggle {
            border: none;
            outline: none;
            padding: 6px 10px;
            transition: all 0.3s ease;
          }

          @media (max-width: 992px) {
            .custom-navbar .navbar-collapse {
              position: absolute;
              top: 100%;
              left: 0;
              width: 100%;
              background: white;
              padding: 15px 0;
              box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
              z-index: 1000;
              animation: fadeIn 0.3s ease-in-out;
            }
            
            @keyframes fadeIn {
              from {
                opacity: 0;
                transform: translateY(-10px);
              }
              to {
                opacity: 1;
                transform: translateY(0);
              }
            }

            .custom-navbar .nav-link {
              text-align: center;
              font-size: 18px;
              padding: 10px 0;
              width: 100%;
              display: block;
            }

            .custom-navbar .dropdown-menu {
              width: 100%;
              text-align: center;
            }
          }
        `}
      </style>
    </Navbar>
  );
};

export default MyNavbar;
