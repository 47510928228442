import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import ImageFrame from '../../components/ImageFrame';
import eco from '../../assets/Icons/eco.png';
import instantquote from '../../assets/Icons/instantquote.png';
import lowmoq from '../../assets/Icons/lowmoq.png';
import best from '../../assets/Icons/best.png';
import image from '../../assets/Services(Printing)/Printing 1.png';
import Sample from '../../components/Sample'
import ContactUs from '../../components/ContactUs';
import { Subheading, Heading } from '../../components/Headings';
import serviceImages from '../../assets/Services(Printing)/services';
import ProductCard from '../../components/MockupProducts';
import { useNavigate } from "react-router-dom";
import Workflow from '../../components/Process';
import images from '../../assets/Printing - Featured Products/images';
import Section from '../../components/Section';
import { servicePageStyles } from './shared';

export default function Printing() {
    const productData = [
        {
          image: [
            images.brochure,
          ],
          title: "Brochures",
          tagline: "Impactful, eye-catching designs.",
          description: "High-quality brochures designed to effectively showcase your message, perfect for marketing and promotions."
        },
        {
          image: [
            images.calendar,
          ],
          title: "Calendars",
          tagline: "Stay organized in style.",
          description: "Customizable calendars for homes or offices, helping you track important dates with a personalized touch."
        },
        {
          image: [
            images.card,
          ],
          title: "Greeting Cards",
          tagline: "Celebrate with style.",
          description: "Beautifully crafted greeting cards for every occasion, designed to add a personal and thoughtful touch."
        },
        {
          image: [
            images.folder,
          ],
          title: "Folders",
          tagline: "Organize with ease.",
          description: "Stylish and functional folders to keep your documents organized and presentable for any professional setting."
        }
      ];
      
      
 const navigate = useNavigate();

    const features = [
        { image: eco, title: "Eco-friendly materials", description: "Sustainable packaging to promote reusability." },
        { image: instantquote, title: "Instant Quotation", description: "Consult and get an estimate immediately" },
        { image: lowmoq, title: "Low MOQs", description: "Custom made boxes to cater to your needs" },
        { image: best, title: "Best Quality", description: "Ensuring quality produce for our clients" },
    ];

    return (
        <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            
            {/* First Section */}
            <Section style={servicePageStyles.section}>
                <Container style={servicePageStyles.container}>
                <Row className='align-items-center'>
                        <Col xs={12} md={7}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                                <h1 style={servicePageStyles.subheading}>Our Services</h1>
                    <div
                        style={{
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                        backgroundColor: '#F46036',
                        marginLeft: '0.5rem',
                        }}
                    ></div>
                    </div>
                            <h2 style={servicePageStyles.heading}>
                    Smart, Sustainable, and Seamless <span style={{color: '#220C5E'}}>Printing Solutions</span>
                    </h2>
                </Col>
                        <Col xs={12} md={5} className="text-center mt-4 mt-md-0">
                <img src={image} alt="Mockup 1" style={{ maxWidth: '100%', height: 'auto' }} />
                </Col>
                </Row>
            </Container>
            </Section>

            {/* Second Section: Why Choose Us? */}
            <Section>
                <Container>
                <h3 style={{ textAlign: 'center', marginBottom: '0.5rem' }}>
                    Why <span style={{ color: '#0094DE' }}>Choose</span> Us?
                </h3>
                <Row className='justify-content-center gap-5 mt-4'>
                {features.map((item, index) => (
                    <Col xs={12} sm={6} md={2} key={index} className="text-center">
                    <div>
                        <div style={servicePageStyles.featureCard}>
                        <img src={item.image} alt={item.title} style={{ width: '60%' }} />
                        </div>
                        <h6 style={servicePageStyles.featureText.title}>{item.title}</h6>
                        <p style={servicePageStyles.featureText.description}>{item.description}</p>
                    </div>
                    </Col>
                ))}
                </Row>

                </Container>
            </Section>
            
             {/* Third Section: Workflow */}
            <Section>
                <div className="px-3 px-md-5">
                    <Subheading text="Our Services" color="#204AD7" />
                    <Heading text="The Process that Ensures Trust" />
                    <Workflow/>
                </div>
            </Section>

            {/* Fourth Section: Our setup */}
            <Section backgroundColor="#f8f9ff">
                <Container>
                    <div style={{ textAlign: 'center', marginBottom: '3rem' }}>
                        <Subheading text="Our Setup" color="#0094DE" />
                        <Heading text="Providing quality through innovation" />
                        <p style={{ 
                            fontSize: 'clamp(0.9rem, 2vw, 1.1rem)',
                            color: '#666',
                            maxWidth: '700px',
                            margin: '1.5rem auto 0',
                            lineHeight: '1.6',
                            padding: '0 1rem'
                        }}>
                            Discover our state-of-the-art facilities and innovative processes that ensure the highest quality printing solutions
                        </p>
                    </div>
                    <Row className="justify-content-center g-4">
                        {serviceImages.map((item, index) => (
                            <Col key={index} xs={12} sm={6} md={3} className="d-flex justify-content-center">
                                <div style={{ maxWidth: '300px', width: '100%' }}>
                                    <ImageFrame 
                                        imagePath={item.image} 
                                        caption={item.caption} 
                                    />
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </Section>

            {/* Fifth Section: Try Us */}
            <div style={{ padding: '5rem 0' }}>
                <Container>
                <Sample />
                </Container>
            </div>

            {/* Sixth Section: Mockup Products */}
            <Section backgroundColor="#f8f9ff">
                <Container>
                    <h2 style={{ 
                        textAlign: 'center', 
                        ...servicePageStyles.heading,
                        color: '#F46036',
                        fontSize: 'clamp(1.5rem, 3vw, 2rem)'
                    }}>Mockup Products</h2>
                    <Row className="justify-content-center g-4">
                        {productData.map((item, index) => (
                            <Col key={index} xs={12} sm={6} md={4} lg={3} className="d-flex justify-content-center">
                                <ProductCard 
                                    image={item.image} 
                                    title={item.title}
                                    tagline={item.tagline} 
                                    description={item.description}
                                />
                            </Col>
                        ))}
                    </Row>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '2rem' }}>
                    <button 
                        onClick={() => navigate("/products")} 
                        style={servicePageStyles.linkButton}
                        onMouseOver={(e) => e.target.style.color = '#d6492d'}
                        onMouseOut={(e) => e.target.style.color = '#F46036'}
                    >
                        See More 
                        <span style={{ marginLeft: '0.5rem', transform: 'rotate(-45deg)' }}>→</span>
                    </button>
                    </div>
                </Container>
            </Section>

            <div style={{ padding: '2rem 0'}}>
            <ContactUs/>
            </div>
        </div>

    )
}