import Setup1 from './Packaging/Bobst-Pasting-Machine.jpg';
import Setup2 from './Packaging/Bottom-Pasting.jpg';
import Setup3 from './Packaging/Counting-Machine-1.jpg';
import Setup4 from './Packaging/Crosland-Handfeed-Die-Cutting.jpg';
import Setup5 from './Packaging/Folding-Machine.jpg';
import Setup6 from './Packaging/Heidelberg-Cylinder-Die-Cutting.jpg';
import Setup7 from './Packaging/Menual-Cutting-Machine.jpg';
import Setup8 from './Packaging/Pin-Machine.jpg';
import Setup9 from './Packaging/Plate-Making-Machine.jpg';
import Setup10 from './Packaging/Polar-Cutting-Machine.jpg';
import Setup11 from './Packaging/Roller-Washer.jpg';
import Setup12 from './Packaging/Strappingmachine.jpg';
import Setup13 from './Packaging/papercutting.jpg';
import Setup14 from './Packaging/pasting-1.jpg';
import Setup15 from './Packaging/plat-2.jpg';
import Setup16 from './Packaging/platw.jpeg';
import Setup17 from './Packaging/solna.jpg';
import Setup18 from './Packaging/sork.jpg';

const serviceImages = [
  { image: Setup1, caption: 'Bobst Pasting Machine' },
  { image: Setup2, caption: 'Bottom Pasting' },
  { image: Setup3, caption: 'Paper Counting Machine' },
  { image: Setup4, caption: 'Crosland Handfeed Die Cutting' },
  { image: Setup5, caption: 'Folding Machine' },
  { image: Setup6, caption: 'Heidelberg Cylinder Die Cutting' },
  { image: Setup7, caption: 'Manual Cutting Machine' },
  { image: Setup8, caption: 'Pin Machine' },
  { image: Setup9, caption: 'Plate Making Machine' },
  { image: Setup10, caption: 'Polar Cutting Machine' },
  { image: Setup11, caption: 'Roller Washer' },
  { image: Setup12, caption: 'Strapping Machine' },
  { image: Setup13, caption: 'Paper Cutting (POLAR)' },
  { image: Setup14, caption: 'Pasting (RITCHE)' },
  { image: Setup15, caption: 'Plate Making (PARKER)' },
  { image: Setup16, caption: 'Plate Washer' },
  { image: Setup17, caption: 'SOLNA 125 (SWEDEN)' },
  { image: Setup18, caption: 'SORK (HEIDELBERG) Single Color' },

];

export default serviceImages;
